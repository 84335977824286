import request from '@/utils/request'
const baseURL = '/baseapi'
const ComplaintApi = {
  ComplaintList: '/admin/complaint/list',
  ComplaintInfo: '/admin/complaint/complaintInfo',
  DelComplaint: '/admin/complaint/delComplaint'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function complaintList (parameter) {
  return request({
    baseURL: baseURL,
    url: ComplaintApi.ComplaintList,
    method: 'post',
    data: parameter
  })
}

export function complaintInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: ComplaintApi.ComplaintInfo,
    method: 'post',
    data: parameter
  })
}

export function delComplaint (parameter) {
  return request({
    baseURL: baseURL,
    url: ComplaintApi.DelComplaint,
    method: 'post',
    data: parameter
  })
}
